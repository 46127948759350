import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpXsrfTokenExtractor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TitleCasePipe } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { XsrfInterceptor } from './auth/xsrf.interceptor';
import { UnAuthenticatedInterceptor } from './auth/unauthenticated.interceptor';
import { ContentTypeInterceptor } from './common-services/content-type.interceptor';
import { CurrencyPipe } from '@angular/common';
import { CurrencyItaPipe } from './common-utilities/currency-ita.pipe';
import { AutoFocusDirective } from './common-utilities/auto-focus.directive';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { TimestampsInterceptor } from './common-services/timestamps.interceptor';
import { SafeHtmlPipe } from './common-utilities/safe-html.pipe';
import { ColorStatusPipe } from './common-utilities/color-status.pipe';
import { LuxonDatePipe } from './common-utilities/luxon-date.pipe';
import { WeekdayToStringPipe } from './common-utilities/weekday-to-string.pipe';
import { IntegerCurrencyValueAccessorDirective } from './common-utilities/integer-currency-value-accessor.directive';
import { DoposcuolaPaymentInfoComponent } from './common-components/doposcuola-payments/doposcuola-payment-info.component';
import { BasePaymentInfoComponent } from './common-components/base-payment-info/base-payment-info.component';
import { ContentTemplateRefDirective } from './common-utilities/content-template-ref.directive';
import { ServiceUomPipe } from './common-utilities/uom.pipe';


// declare components, directives, and pipes in a shared module when those items will be re-used
// and referenced by the components declared in other feature modules.
//
// SharedModule exists to make commonly used components, directives and pipes available for use in
// the templates of components in many other modules
//
// NO services in shared modules.
// A lazy loaded feature module that imports that shared module will make its own copy of the service and likely have undesirable results

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    // material
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatLuxonDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatMenuModule,
    MatTreeModule,
    MatToolbarModule,
    ScrollingModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatRippleModule,
    MatStepperModule,
    ClipboardModule,
  ],
  declarations: [
    CurrencyItaPipe,
    LuxonDatePipe,
    WeekdayToStringPipe,
    SafeHtmlPipe,
    ServiceUomPipe,
    AutoFocusDirective,
    ContentTemplateRefDirective,
    ColorStatusPipe,
    IntegerCurrencyValueAccessorDirective,
    BasePaymentInfoComponent,
    DoposcuolaPaymentInfoComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    CurrencyItaPipe,
    LuxonDatePipe,
    WeekdayToStringPipe,
    SafeHtmlPipe,
    ServiceUomPipe,
    AutoFocusDirective,
    ContentTemplateRefDirective,
    ColorStatusPipe,
    IntegerCurrencyValueAccessorDirective,
    // material
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatLuxonDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatMenuModule,
    MatTreeModule,
    MatToolbarModule,
    ScrollingModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatRippleModule,
    MatStepperModule,
    ClipboardModule,
    BasePaymentInfoComponent,
    DoposcuolaPaymentInfoComponent,
  ],
  providers: [
        TitleCasePipe,
        CurrencyPipe,
        { provide: MAT_DATE_LOCALE, useValue: 'it' },
        { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimestampsInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UnAuthenticatedInterceptor, multi: true },
    ],
})
export class SharedModule {
    constructor() {
        const ts = new Date();
        console.log('[' + ts.toLocaleTimeString() + '.' + ts.getMilliseconds() + '] Loading module: "SharedModule"');
    }
}

